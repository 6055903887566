import * as React from "react";
import {
  Header,
  Container,
  Title,
  SubTitle,
  Content,
} from "../components/styles";
import Pattern from "../images/contact.png";
import styled from "styled-components";
import Accordion from "../components/accordion";
import SEO from "../components/seo";

const HeaderCustom = styled((props) => <Header {...props} />)`
  &:after {
    background-image: url(${Pattern});
  }
`;

const Form = styled.form`
  background: #f6ecea;
  padding: 20px;
  border-radius: 15px;
  max-width: 400px;
  label {
    display: block;
    margin-bottom: 10px;

    span {
      display: block;
    }
  }

  input,
  textarea {
    font-family: brandon-grotesque, sans-serif;
    display: block;
    width: 100%;
    background: none;
    border: 0;
    border-bottom: 2px solid #e8e0df;
    padding: 10px;

    &:focus {
      outline: 0;
      border-bottom: 2px solid #b5a5a3;
    }
  }

  textarea {
    height: 100px;
  }

  button {
    display: block;
    margin: 0 auto;
    width: 120px;
    font-family: brandon-grotesque, sans-serif;
    color: #17130f;
    text-decoration: none;
    font-size: 1.2rem;
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    border: none;
  }
`;

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO
        title="Book a Cake"
        description="Get in touch to order bespoke buttercream cakes for any occasion in Rugby, Warwickshire."
      />
      <HeaderCustom>
        <Container>
          <Title>Book a cake</Title>
        </Container>
      </HeaderCustom>
      <Container>
        <Content>
          <div>
            <SubTitle>FAQs</SubTitle>
            <Accordion
              title="Where are you based?"
              content="The Stanley Bakes is based in Rugby, Warwickshire."
            />
            <Accordion
              title="Do you deliver?"
              content="Local delivery options are available around Rugby and the surrounding areas. Additional delivery charges will apply."
            />
            <Accordion
              title="Can I request a custom flavour not listed?"
              content="The main flavours listed on my menu are the most popular, with tried and tested recipes. I can make small variations in most circumstances, so let me know if your favourite flavour is not on the list!"
            />
            <Accordion
              title="Do you cater for allergens?"
              content="We take allergies very seriously at The Stanley Bakes. Due to my own allergy to nuts, unfortunately, I cannot use a large majority of nuts in my baking. I am also unable to offer egg-free, dairy-free or gluten-free cakes due to the allergens present in the kitchen."
            />
            <Accordion
              title="How much notice do you need when I book my cake?"
              content="At least two weeks notice, but I take bookings up to a year in advance for larger celebration and wedding cakes."
            />
            <Accordion
              title="How do you take payment?"
              content="On confirmation of the date being booked for your cake, a 50% deposit is required. The final 50% of the payment is required two weeks before the date of your cake. If for any reason you decide to cancel within two weeks before the date, in some circumstances we may be able to move your date, but we will not be able to provide any refunds."
            />
          </div>
          <div>
            <SubTitle>Book your next cake</SubTitle>
            <p>
              We aim to respond to your query within 48 hours with a quote and
              confirmation if we have availability on the date required. Please
              provide as much information as possible. All fields are required.
            </p>
            {/* <Form
              method="post"
              action="https://getform.io/f/8a705a43-b68d-4ef7-ab6a-243d242d47a4"
              on
            >
              <label>
                <span>Name</span>
                <input type="text" name="name" required />
              </label>
              <label>
                <span>Email</span>
                <input type="email" name="email" required />
              </label>
              <label>
                <span>Phone</span>
                <input type="tel" name="phone" required />
              </label>

              <label>
                <span>Occasion</span>
                <input
                  type="text"
                  name="occasion"
                  placeholder="e.g. Birthday, Wedding, just because..."
                  required
                />
              </label>
              <label>
                <span>Date Required</span>
                <input
                  type="date"
                  name="when"
                  required
                  min={new Date().toISOString().split("T")[0]}
                />
              </label>
              <label>
                <span>Message</span>
                <textarea
                  type="text"
                  name="message"
                  required
                  placeholder="Please include flavour, size and type of cake(s) required, along with any dietry requirements."
                ></textarea>
              </label>
              <button type="submit">Book Now</button>
            </Form> */}
            <p>Not currently taking orders.</p>
          </div>
        </Content>
      </Container>
    </React.Fragment>
  );
};

export default IndexPage;
